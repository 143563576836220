/*
| Developed by Starton
| Filename: PasswordField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { StartonIconButton, StartonTextField } from '@starton/react-ui'
import { RemoveRedEyeOutlined } from '@mui/icons-material'
import { Field, useFormikContext } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { Box, styled } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PasswordFieldProps {
	name?: string
	label?: React.ReactNode
	helperText?: React.ReactNode
	onValueChange?: (value: string) => void
	showValidation?: boolean
	disabled?: boolean
	dataTestId?: string
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const LevelContainerStyled = styled(Box, {
	shouldForwardProp: (propName: PropertyKey) => propName !== 'backgroundColor',
})<{ backgroundColor: 'error' | 'warning' | 'info' | 'success' | 'primary' }>(({ theme, backgroundColor }) => ({
	display: 'flex',
	gap: theme.spacing(1),
	'& > div': {
		backgroundColor: theme.palette[backgroundColor].main,
	},
}))

const LevelStyled = styled(Box, {
	shouldForwardProp: (propName: PropertyKey) => propName !== 'colored',
})<{ colored?: boolean }>(({ theme, colored }) => ({
	height: theme.spacing(0.5),
	width: '100%',
	borderRadius: theme.spacing(1),
	...(!colored && {
		backgroundColor: theme.palette.divider + ' !important',
	}),
}))

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const passwordColors = ['error', 'error', 'error', 'warning', 'warning', 'primary'] as const

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PasswordField: React.FC<PasswordFieldProps> = ({
	name,
	label,
	helperText,
	onValueChange,
	showValidation,
	disabled,
	dataTestId,
}) => {
	const { values } = useFormikContext<{ [key: string]: string }>()
	const { t } = useTranslation('common')
	const [showPassword, setShowPassword] = React.useState(false)
	const [passwordLevel, setPasswordLevel] = React.useState(0)

	React.useEffect(() => {
		onValueChange?.(values[name ?? 'password'])
	}, [name, onValueChange, values])

	React.useEffect(() => {
		let _level = 0
		const password = values[name ?? 'password']
		if (password.length >= 8) _level++
		if (/[A-Z]/.test(password)) _level++
		if (/[a-z]/.test(password)) _level++
		if (/.*\d.*/.test(password)) _level++
		if (/[^a-zA-Z0-9]/.test(password)) _level++

		setPasswordLevel(_level)
	}, [name, values])

	// Render
	//--------------------------------------------------------------------------
	return (
		<Box display="flex" flexDirection="column" gap={1}>
			<Field
				name={name ?? 'password'}
				component={StartonTextField}
				helperText={helperText}
				label={label ?? t('common.password')}
				InputLabelProps={{
					sx: {
						width: '100%',
					},
				}}
				disabled={disabled}
				placeholder="••••••••••••"
				type={showPassword ? 'text' : 'password'}
				InputProps={{
					endAdornment: (
						<StartonIconButton onClick={() => setShowPassword((state) => !state)}>
							<RemoveRedEyeOutlined />
						</StartonIconButton>
					),
				}}
				data-testid={dataTestId || 'password'}
			/>
			{showValidation ? (
				<LevelContainerStyled backgroundColor={passwordColors[passwordLevel]}>
					<LevelStyled colored={passwordLevel >= 1} />
					<LevelStyled colored={passwordLevel >= 2} />
					<LevelStyled colored={passwordLevel >= 3} />
					<LevelStyled colored={passwordLevel >= 4} />
					<LevelStyled colored={passwordLevel >= 5} />
				</LevelContainerStyled>
			) : null}
		</Box>
	)
}
