/*
| Developed by Starton
| Filename : starton-server-side-headers.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { ServerResponse } from 'http'
import moment from 'moment'

export const setDefaultSSRHeaders = (res: ServerResponse) => {
	res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')
	res.setHeader('X-DNS-Prefetch-Control', 'on')
	res.setHeader('Strict-Transport-Security', 'max-age=63072000; includeSubDomains; preload')
	res.setHeader('X-XSS-Protection', '1; mode=block')
	res.setHeader('X-Frame-Options', 'SAMEORIGIN')
	res.setHeader('X-Content-WatcherType-Options', 'nosniff')
	res.setHeader('Last-Modified', moment().format('ddd, DD MMM YYYY HH:mm:ss ') + 'GMT')
}
