/*
| Developed by Starton
| Filename : starton-server-side-props.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { ParsedUrlQuery } from 'querystring'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { PreviewData } from 'next/types'
import { StartonPageFactoryProps } from './StartonPage'
import { setDefaultSSRHeaders } from './starton-server-side-headers'
import { UrlsConfig } from '@/config/urls/urls.config'
import { CookiesService } from '@/services/cookies/cookies.service'
import {
	FAPI_VERSION_RELEASE_NOTES,
	fapiAxiosInstance,
	VersionReleaseNotes,
	FAPI_MAINTENANCES,
	maintenancesGetAll,
} from '@/services/fapi'
import { AUTH_API_WHOAMI, whoami } from '@/services/auth-api/endpoints/users'
import { projectGetAll } from '@/services/api/starton/endpoints/internal/internal'
import { Project } from '@/services/api/starton/model'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/

/**
 * Options for axios instance on server side.
 */
export interface StartonServerSideAxiosOptions {
	headers: {
		cookie?: string
		'x-tenant-name'?: string
		'x-project-id'?: string
	}
}

/**
 * Includes all data that is passed to the serverSideProps. Useful in cases where we need to send data intended only for servers or shared data.
 */
export interface StartonServerSideData {}

/**
 * Utils
 */
export interface StartonServerSidePropsParams<P extends StartonPageFactoryProps> {
	props: P
	ssrData: StartonServerSideData
	context: Parameters<GetServerSideProps>[0]
}

/*
|--------------------------------------------------------------------------
| Create server side props factory
|--------------------------------------------------------------------------
*/
export const createStartonServerSideProps = async <
	Q extends ParsedUrlQuery = ParsedUrlQuery,
	D extends PreviewData = PreviewData,
>(
	context: GetServerSidePropsContext<Q, D>,
) => {
	// Set default headers.
	// ----------------------------------------------------------------------------
	setDefaultSSRHeaders(context.res)

	// Get maintenances data
	// ----------------------------------------------------------------------------
	const maintenances = await maintenancesGetAll()

	// If global maintenance is activated, then we redirect to /maintenance page
	if (maintenances?.data?.attributes?.dashboardGlobal?.activated && context.resolvedUrl !== '/maintenance') {
		return {
			redirect: {
				destination: `/maintenance`,
				permanent: false,
			},
		}
	}

	return { maintenances }
}

/*
|--------------------------------------------------------------------------
| SSR - Public pages
|--------------------------------------------------------------------------
*/
export const createStartonPublicServerSideProps = <
	P extends StartonPageFactoryProps,
	Q extends ParsedUrlQuery = ParsedUrlQuery,
	D extends PreviewData = PreviewData,
>(
	func?: (params: StartonServerSidePropsParams<P>) => ReturnType<GetServerSideProps<P, Q, D>>,
): GetServerSideProps<P, Q, D> => {
	console.log('- [createStartonPublicServerSideProps] -')

	return async (context) => {
		try {
			const startonServerSideProps = await createStartonServerSideProps<Q, D>(context)

			// Redirect user to maintenance page
			// ----------------------------------------------------------------------------
			if (startonServerSideProps.redirect) {
				return startonServerSideProps
			}

			// Fill global data for the page.
			// ----------------------------------------------------------------------------
			const props = {
				globalFallback: {
					[FAPI_MAINTENANCES]: startonServerSideProps.maintenances,
				},
			} as P

			// If a callback is defined, then we call it. Otherwise we just send the global data.
			// ----------------------------------------------------------------------------
			if (func) {
				const ssrData: StartonServerSideData = {}

				return func({ props, ssrData, context })
			}

			// Return props.
			// ----------------------------------------------------------------------------
			return { props }
		} catch (error) {
			// @ts-ignore
			console.error(error?.response?.data, error?.response)

			// Redirect user to auth
			// ----------------------------------------------------------------------------
			return {
				redirect: {
					destination: `/500`,
					permanent: false,
				},
			}
		}
	}
}

/*
|--------------------------------------------------------------------------
| SSR - Auth pages
|--------------------------------------------------------------------------
*/
export const createStartonAuthPageServerSideProps = <
	P extends StartonPageFactoryProps,
	Q extends ParsedUrlQuery = ParsedUrlQuery,
	D extends PreviewData = PreviewData,
>(
	func?: (params: StartonServerSidePropsParams<P>) => ReturnType<GetServerSideProps<P, Q, D>>,
): GetServerSideProps<P, Q, D> => {
	console.log('- [createStartonAuthPageServerSideProps] -')

	return async (context) => {
		// const cookie = encodeURIComponent(
		// 	context.req.cookies?.[process?.env?.NEXT_PUBLIC_AUTH_SESSION_NAME as string] as string,
		// )
		//
		// if (cookie !== undefined) {
		// 	console.log('before user whoami')
		// 	const user = await whoami({
		// 		headers: {
		// 			Cookie: `${process?.env?.NEXT_PUBLIC_AUTH_SESSION_NAME as string}=${cookie}`,
		// 		},
		// 	})
		//
		// 	if (user) {
		// 		return {
		// 			redirect: {
		// 				destination: UrlsConfig.home,
		// 				permanent: false,
		// 			},
		// 		}
		// 	}
		// }

		try {
			const startonServerSideProps = await createStartonServerSideProps<Q, D>(context)

			// Redirect user to maintenance page
			// ----------------------------------------------------------------------------
			if (startonServerSideProps.redirect) {
				return startonServerSideProps
			}

			// Fill global data for the page.
			// ----------------------------------------------------------------------------
			const props = {
				globalFallback: {
					[FAPI_MAINTENANCES]: startonServerSideProps.maintenances,
				},
			} as P

			// If a callback is defined, then we call it. Otherwise we just send the global data.
			// ----------------------------------------------------------------------------
			if (func) {
				const ssrData: StartonServerSideData = {}

				return func({ props, ssrData, context })
			}

			// Return props.
			// ----------------------------------------------------------------------------
			return { props }
		} catch (error) {
			// @ts-ignore
			console.error(error?.response?.data, error?.response)

			// Redirect user to auth
			// ----------------------------------------------------------------------------
			return {
				redirect: {
					destination: `/500`,
					permanent: false,
				},
			}
		}
	}
}

/*
|--------------------------------------------------------------------------
| SSR - Authenticated
|--------------------------------------------------------------------------
*/
export const createStartonAuthenticatedServerSideProps = <
	P extends StartonPageFactoryProps,
	Q extends ParsedUrlQuery = ParsedUrlQuery,
	D extends PreviewData = PreviewData,
>(
	func?: (params: StartonServerSidePropsParams<P>) => ReturnType<GetServerSideProps<P, Q, D>>,
): GetServerSideProps<P, Q, D> => {
	console.log('- [createStartonAuthenticatedServerSideProps] -')

	return async (context) => {
		try {
			const startonServerSideProps = await createStartonServerSideProps<Q, D>(context)

			// Redirect user to maintenance page
			// ----------------------------------------------------------------------------
			if (startonServerSideProps.redirect) {
				return startonServerSideProps
			}

			// Get user from Starton Auth
			// ----------------------------------------------------------------------------
			const cookie = encodeURIComponent(
				context.req.cookies?.[process?.env?.NEXT_PUBLIC_AUTH_SESSION_NAME as string] as string,
			)
			const user = await whoami({
				headers: {
					Cookie: `${process?.env?.NEXT_PUBLIC_AUTH_SESSION_NAME as string}=${cookie}`,
				},
			})

			// Get last product updates
			// ----------------------------------------------------------------------------
			const productUpdates = await fapiAxiosInstance<VersionReleaseNotes>({
				url: FAPI_VERSION_RELEASE_NOTES,
				method: 'GET',
			})

			// If project id is 'default' then we need redirect to default project
			// ----------------------------------------------------------------------------
			if (context.query?.projectId === 'default') {
				try {
					// Get get all projects
					const projectList = await projectGetAll(undefined, {
						headers: {
							cookie,
						},
					})

					// If project list is empty, then redirect to `/projects`
					if (!projectList.items || projectList.items.length === 0) {
						return {
							redirect: {
								destination: '/projects',
								permanent: false,
							},
						}
					}

					// We define default project id to use
					const lastProjectIdUsed = CookiesService.getLastProjectId({
						res: context.res,
						req: context.req,
					})
					if (lastProjectIdUsed) {
						const defaultProjectId = projectList.items.find(
							(project: Project) => project.id === lastProjectIdUsed,
						)

						if (defaultProjectId?.id) {
							return {
								redirect: {
									destination: context.resolvedUrl.replace('default', defaultProjectId.id),
									permanent: false,
								},
							}
						}
					}

					return {
						redirect: {
							destination: context.resolvedUrl.replace('default', projectList.items[0].id),
							permanent: false,
						},
					}
				} catch (error) {
					console.error(error)
				}
			}

			// Get project id
			// ----------------------------------------------------------------------------
			const projectId = context.query?.projectId ?? null

			if (projectId) {
				CookiesService.setSSRProjectId(projectId as string, {
					res: context.res,
					req: context.req,
				})
			}

			// Fill global data for the page.
			// ----------------------------------------------------------------------------
			const props = {
				globalFallback: {
					[AUTH_API_WHOAMI]: user,
					[FAPI_VERSION_RELEASE_NOTES]: productUpdates,
					[FAPI_MAINTENANCES]: startonServerSideProps.maintenances,
				},
				projectId,
			} as P

			// If a callback is defined, then we call it. Otherwise we just send the global data.
			// ----------------------------------------------------------------------------
			if (func) {
				const ssrData: StartonServerSideData = {}

				return func({ props, ssrData, context })
			}

			// Return props.
			// ----------------------------------------------------------------------------
			return { props }
		} catch (error) {
			// @ts-ignore
			console.error(error?.response?.data)

			// Get preset from query string
			// ----------------------------------------------------------------------------
			const preset = context.query?.preset as string

			// Prepare redirect_to URL for Starton auth
			// ----------------------------------------------------------------------------
			const baseURL = `https://${context.req.headers.host as string}${context.req.url as string}`

			// Redirect user to auth
			// ----------------------------------------------------------------------------
			return {
				redirect: {
					destination: preset
						? `${UrlsConfig.auth.login}?preset=${preset}&redirect_to=${baseURL}`
						: `${UrlsConfig.auth.login}?redirect_to=${baseURL}`,
					permanent: false,
				},
			}
		}
	}
}
