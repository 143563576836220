/*
| Developed by Starton
| Filename : StartonPage.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { SWRConfig, SWRConfiguration } from 'swr'
import { swrProjectIdMiddleware } from '@/services/api/middlewares/swr-project-id.middleware'
import { axiosInstance } from '@/services/api/mutators/axios-custom-instance'
import { SessionStorageService } from '@/services/api/sessionStorage/sessionStorage.service'
import { FAPI_MAINTENANCES, FAPI_VERSION_RELEASE_NOTES, MaintenanceDTO, VersionReleaseNotes } from '@/services/fapi'
import { AUTH_API_WHOAMI } from '@/services/auth-api/endpoints/users'
import { User } from '@/services/auth-api/contracts/users'
import { StartonPage } from '@/contracts/next-layout'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/

/**
 * This props wil be extended by every page.
 */
export interface StartonPageFactoryProps {
	globalFallback: {
		[AUTH_API_WHOAMI]: User
		[FAPI_VERSION_RELEASE_NOTES]: VersionReleaseNotes
		[FAPI_MAINTENANCES]: MaintenanceDTO
	}
	projectId: string | null
	swrConfig?: SWRConfiguration
}

/*
|--------------------------------------------------------------------------
| Starton page factory wrapper
|--------------------------------------------------------------------------
*/
const StartonPageFactoryWrapper: React.FC<StartonPageFactoryProps & { children: React.ReactElement }> = (props) => {
	React.useEffect(() => {
		if (props.projectId) {
			axiosInstance.defaults.headers.common['x-project-id'] = props.projectId
			SessionStorageService.setItem('starton.current-project-id', props.projectId)
		} else {
			delete axiosInstance.defaults.headers.common['x-project-id']
			SessionStorageService.removeItem('starton.current-project-id')
		}
	}, [props.projectId])

	return (
		<React.Fragment>
			<SWRConfig value={{ fallback: props.globalFallback, use: [swrProjectIdMiddleware], ...props.swrConfig }}>
				{props.children}
			</SWRConfig>
		</React.Fragment>
	)
}

/*
|--------------------------------------------------------------------------
| Starton page factory
|--------------------------------------------------------------------------
*/
export function StartonPageFactory<PageProps extends StartonPageFactoryProps>(
	Page: React.FC<PageProps>,
): StartonPage<PageProps> {
	const StartonPageFactoryWrapped: StartonPage<PageProps> = (props) => (
		<StartonPageFactoryWrapper {...props}>
			<Page {...props} />
		</StartonPageFactoryWrapper>
	)

	StartonPageFactoryWrapped.displayName = 'StartonPageFactory'

	return StartonPageFactoryWrapped
}
