/*
| Developed by Starton
| Filename : swr-project-id.middleware.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Middleware, SWRHook } from 'swr'
import { useRouter } from 'next/router'
import { axiosInstance } from '../mutators/axios-custom-instance'
import { SessionStorageService } from '../sessionStorage/sessionStorage.service'

export const swrProjectIdMiddleware: Middleware = (useSWRNext: SWRHook) => (key, fetcher, config) => {
	const router = useRouter()
	const currentProjectId = React.useMemo(() => {
		return router.query?.projectId ?? undefined
	}, [router.query?.projectId])

	React.useEffect(() => {
		if (currentProjectId) {
			axiosInstance.defaults.headers.common['x-project-id'] = currentProjectId
			SessionStorageService.setItem('starton.current-project-id', currentProjectId as string)
		} else {
			delete axiosInstance.defaults.headers.common['x-project-id']
			SessionStorageService.removeItem('starton.current-project-id')
		}
	}, [currentProjectId])

	return useSWRNext(key, fetcher, config)
}
